import React from "react";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const CookiePolicy = () => {
  const lang = "de";

  return (
    <Layout
      seoTitle="Cookie Politik"
      lang={lang}
      translationPL=""
      translationEN=""
    >
      <SmallBanner title="Cookie Politik" lang={lang} />

      <section className="default-main">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <p>
                Durch die Nutzung der Website pallettenwerk.pl erklären Sie sich
                mit der Verwendung von Cookies in Übereinstimmung mit dieser
                Cookie-Politik einverstanden. Wenn Sie mit der Verwendung von
                Cookies durch uns nicht einverstanden sind, ändern Sie
                entsprechend die Einstellungen Ihres Browsers oder beenden Sie
                die Nutzung der Website pallettenwerk.pl.
              </p>
              <h4>Was sind Cookies?</h4>
              <p>
                Cookies sind kleine Dateien, die auf Ihrem Computer, Tablet oder
                Smartphone gespeichert und aufbewahrt werden, während Sie
                verschiedene Seiten im Internet besuchen. Ein Cookie enthält in
                der Regel den Namen der Website, von der es stammt, die
                "Lebensspanne" des Cookies (d.h. die Dauer seines Bestehens) und
                eine nach dem Zufallsprinzip generierte eindeutige Nummer zur
                Identifizierung des Browsers, von dem aus die Website verlinkt
                ist.
              </p>
              <h4>Wozu verwenden wir Cookies?</h4>
              <p>
                palettenwerk.pl verwendet Cookies für verschiedene Zwecke: um
                die Website schneller und benutzerfreundlicher zu machen, um
                anonyme, aggregierte Statistiken zu sammeln, die uns helfen zu
                verstehen, wie die Personen unsere Websites nutzen, sie helfen
                uns, die Funktionalität und den Inhalt der Websites zu
                verbessern. Wenn wir Cookies wie oben beschrieben verwenden,
                identifizieren wir niemals die Identität der Benutzer anhand der
                in den Cookies gespeicherten Informationen.
              </p>
              <h4>Wie lange werden die Daten in Cookies gespeichert?</h4>
              <p>
                Auf der Website paltenwerk.pl können zwei Arten von Cookies
                verwendet werden - Session-Cookies und persistente Cookies. Der
                erste Typ verbleibt nur dann auf Ihrem Gerät, wenn Sie die
                Website pallettenwerk.pl verwenden. Persistente Cookies
                verbleiben so lange auf Ihrem Gerät, wie lang ihre Lebensspanne
                ist oder bis Sie sie löschen.
              </p>
              <h4>
                Arten von Cookies, die auf pallettenwerk.pl verwendet werden
              </h4>
              <p>
                Cookies erforderlich für den Betrieb der Website - Erforderlich
                für das ordnungsgemäße Funktionieren der Website
                pallettenwerk.pl, damit Sie sich auf der Website bewegen und
                ihre Elemente verwenden können. Sie können beispielsweise
                frühere Aktionen (z.B. offene Texte) speichern, wenn Sie in der
                gleichen Sitzung auf die Website zurückkehren.
              </p>
              <p>
                Zur Verbesserung der Effizienz - Sammeln von Informationen
                darüber, wie die Besucher die Website pallettenwerk.pl nutzen,
                indem sie Informationen über die Bereiche, die sie besuchen, die
                Zeit, die sie dort verbringen, und die Probleme, auf die sie
                stoßen, wie z. B. Fehlermeldungen, bereitstellen. Damit können
                wir die Website pallettenwerk.com verbessern.
              </p>
              <h4>Benutzen wir Cookies von Dritten?</h4>
              <p>
                Ja, wenn Sie den Service pallettenwerk.pl verwenden, können Sie
                Cookies von Dritten erhalten, die mit pallettenwerk.pl
                zusammenarbeiten, wie Facebook oder Google. Weitere
                Informationen über diese Cookies finden Sie auf den Websites
                dieser Dritten.
              </p>
              <h4>
                Wie kann ich meine Cookie-Einstellungen ändern oder Cookies
                löschen?
              </h4>
              <p>
                Die meisten Webbrowser sind anfänglich so eingestellt, dass sie
                Cookies automatisch akzeptieren. Sie können jedoch Ihre
                Browsereinstellung so ändern, dass Cookies ganz oder teilweise
                blockiert werden, z.B. nur von Dritten, oder dass Sie jedes Mal
                eine Nachricht erhalten, wenn Cookies an Ihr Gerät gesendet
                werden. Bitte beachten Sie jedoch, dass die Blockierung der von
                uns verwendeten Cookies die Bequemlichkeit der Nutzung der
                Website pallettenwerk.com beeinträchtigen kann, z.B. kann es
                vorkommen, dass Sie bestimmte Bereiche der Website
                pallettenwerk.pl nicht besuchen können oder keine
                personalisierten Informationen erhalten, wenn Sie diese
                einsehen. Sie verhindern uns auch die Erhebung von anonymen
                Informationen über die Nutzung, um den Inhalt von
                pallettenwerk.pl kontinuierlich zu verbessern.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CookiePolicy;
